import { Client } from "./Client";
import Passenger from "./Passenger";
import { Status } from "./Status";
import { Tariff } from "./Tariff";
import { Driver, Vehicle } from "./Vehicle";

export enum Period {
  today = "today",
  tomorrow = "tomorrow",
  yesterday = "yesterday",
  future = "future",
  past = "past"
}

export interface Ride {
    id?: number;
    driver?: Driver;
    vehicle?: Vehicle;
    destinationFrom: Location;
    destinationTo: Location;
    //TODO: Use enum
    status: string;
    date: string;
    hour: number;
    minute: number;
    flightNo: string;
    client: Client;
    tariff: Tariff;
    passengers: Passenger[];
  }

  export interface ClientResponse {
    "rideId": number,
    "vehicleId": number,
    "driverId": number
  }

 export interface RideResponse {
    id?: number;
    destinationFrom: Location;
    destinationTo: Location;
    date: string;
    hour: number;
    minute: number;
    flightNo: string;
    bookingCount: number;
    passengerCount: number;
    passengerTotal: number;
    luggageCount: number;
    luggageTotal: number;
    bookingCountUnconfirmed: number;
    passengerCountUnconfirmed: number;
    luggageCountUnconfirmed: number;
    status: Status;
    originalPrice: number;
    currentPrice: number;
    bestPrice: number;
    yourSharePrice: number;
    companyName: string;
  }
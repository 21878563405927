import { Routes, Route, Link } from "react-router-dom";
import AccountPage from "@components/Account/AccountPageTEST";
import AccountSettings from "@components/Account/AccountSettings";
import NoMatch from "@components/ErrorLandingPages/NoMatch";
import AddressPage from "@components/Address/AddressPage";
import Dashboard from "@components/Home/Dashboard";
import Login from "@components/Login/Login";
import {
  Authority,
  isAuthenticated,
  tokenHasAuthority,
} from "@utils/authUtils";
import { useState } from "react";
import withAuthentication from "@components/common/AuthenticatedPage";
import withAuthorization from "@components/common/AuthorizedPage";
import { useAppDispatch } from "@store/hooks";
import { clearAccounts } from "@components/Account/accountSlice";
import { clearAddresses } from "@components/Address/addressSlice";
import Contacts from "../Address/Contacts";
import ShareARide from "../ShareARide/ShareARide";
import ManageMyBooking from "../ShareARide/ManageMyBooking";
import FleetManagement from "../ShareARide/Client/FleetManagement";
import TariffManagement from "../ShareARide/Client/TariffManagement";
import BookATrip from "../ShareARide/BookATrip/BookATrip";
import ClientBookings from "../Management/Bookings/ClientBookings";

const Navbar = () => {
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const [authorized] = useState(
    authenticated && tokenHasAuthority(Authority.ROLE_BACKOFFICE)
  );
  const dispatch = useAppDispatch();
  if (!authenticated) {
    dispatch(clearAccounts());
    dispatch(clearAddresses());
  }

  // Authenticated Pages
  const AddressPageWithAuthentication = withAuthentication(AddressPage);
  const ContactsWithAuthentication = withAuthentication(Contacts);
  const DashboardWithAuthentication = withAuthentication(Dashboard);
  const AccountSettingsWithAuthentication = withAuthentication(AccountSettings);

  // Authorization Pages
  const AccountsPageWithAuthorization = withAuthorization(AccountPage);
  const FleetManagementWithAuthorization = withAuthorization(FleetManagement);
  const TariffManagementWithAuthorization = withAuthorization(TariffManagement);
  const ClientBookingsWithAuthorization = withAuthorization(ClientBookings);

  return (
    <div className="container-fluid">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link to="/" className="navbar-brand">
        <img src={require('./transfero_logo.png')} style={{marginLeft: "5%", borderRadius: "15px"}} width="208" height="40" className="d-inline-block align-top" alt="logo"/>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/home" className="nav-link">
                Home
                {/* <span className="sr-only">(current)</span> */}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/new-booking" className="nav-link">
                New Booking
                {/* <span className="sr-only">(current)</span> */}
              </Link>
            </li>
            <li className="nav-item">
            <Link to="/booking" className="nav-link">
                    Manage Your Booking
                  </Link>
            </li>
            {/* <li className="nav-item dropdown">
            eslint-disable-next-line
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Share-a-ride
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                  <Link to="/share-a-ride" className="dropdown-item">
                    New Ride
                  </Link>

                  <Link to="/share-a-ride/booking" className="dropdown-item">
                    Manage Your Booking
                  </Link>
              </div>
            </li> */}
            {authenticated && <li className="nav-item dropdown">
            {/* eslint-disable-next-line */}
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Management
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                  <Link to="/client/ride-management" className="dropdown-item">
                    Rides
                  </Link>
                  <Link to="/client/fleet-management" className="dropdown-item">
                    Fleet
                  </Link>
                  <Link to="/client/tariff-management" className="dropdown-item">
                    Tariffs
                  </Link>
              </div>
            </li>}
            <li className="nav-item dropdown">
            {/* eslint-disable-next-line */}
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Settings
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                {authorized && (
                  <Link to="/accountsTestPage" className="dropdown-item">
                    Accounts - Test Page
                  </Link>
                )}
                {authorized && (
                  <Link to="/account" className="dropdown-item">
                    Account Settings
                  </Link>
                )}
                <Link to="/login" className="dropdown-item">
                  {authorized ? "Logout" : "Login"}
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <Routes>
        {/* Public */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/new-booking" element={<BookATrip />} />
        <Route path="/share-a-ride" element={<ShareARide />} />
        <Route path="/booking" element={<ManageMyBooking />} />
        <Route path="/share-a-ride/booking" element={<ManageMyBooking />} />

        {/* Private - With Authenitcation */}
        <Route path="/login" element={<Login setAuth={setAuthenticated} />} />
        <Route path="/dashboard" element={<DashboardWithAuthentication />} />
        <Route path="/account" element={<AccountSettingsWithAuthentication />}/>
        <Route path="/addresses" element={<AddressPageWithAuthentication />} />
        <Route path="/contacts" element={<ContactsWithAuthentication />} />

        {/* Private - With Authorisation */}
        <Route path="/accountsTestPage" element={<AccountsPageWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>}/>
        <Route path="/client/fleet-management" element={<FleetManagementWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="/client/tariff-management" element={<TariffManagementWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="/client/ride-management" element={<ClientBookingsWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default Navbar;
